import React from 'react'
import axios from 'axios'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import PropTypes from 'prop-types'
import { CButton, CModal, CModalBody, CModalHeader } from '@coreui/react'
import { FaChartLine } from "react-icons/fa6";
import DataChart from 'src/views/pages/axle/DataChart'
import { IoClose } from "react-icons/io5";
import ViewTable from './ViewTable'

const dateAddOffset = (dateStr) => {
  const tmpDate = new Date(dateStr)
  // tmpDate.setHours(tmpDate.getHours() + 5);
  // tmpDate.setMinutes(tmpDate.getMinutes() + 30);
  return (
    tmpDate.toTimeString().substr(0, 5) +
    ' ' +
    tmpDate.getDate() +
    '/' +
    (tmpDate.getMonth() + 1) +
    '/' +
    tmpDate.getFullYear()
  )
}
export const dateToStr = (tmpDate) => {
  // const tmpDate = new Date(dateStr);
  // tmpDate.setHours(tmpDate.getHours() + 5);
  // tmpDate.setMinutes(tmpDate.getMinutes() + 30);
  return (
    tmpDate.toTimeString().substr(0, 5) +
    ' ' +
    tmpDate.getDate() +
    '/' +
    (tmpDate.getMonth() + 1) +
    '/' +
    tmpDate.getFullYear()
  )
}
class DRTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {},
      expandedCoach: {},
      coach_tbl_data: [],
      axlel_tbl_data: [],
      device_data: [],
      selectedItems: [],
      visible: false,
      visibleView :false, 
      traindata: ""
      // showFeedbackForm: false,
      // selectedAxleData: null,
    }
  }

  componentDidMount() {
    //console.log("Props ==>", this.props.data);
  }

  getCoachData(trainID) {
    axios
      .get('https://api.irhabd.com/v1/coach-by-device-readings/' + trainID, {})
      .then((res) => {
        this.setState({
          coach_tbl_data: res.data,
          axlel_tbl_data: [],
          expandedCoach: {},
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  getAxleData(coachID) {
    axios
      .get('https://api.irhabd.com/v1/axle-by-coach/' + coachID, {})
      .then((res) => {
        this.setState({
          axlel_tbl_data: res.data,
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  updateData = (result, index, table, deviceID) => {
    /* if (table === 'device'){
      this.props.updateDeviceData(result, index, deviceID);
    }
    else if (table === 'coach'){
      const coach_tbl_data = [...this.state.coach_tbl_data];
      coach_tbl_data[index] = result.data[0];
      this.setState({ coach_tbl_data });
    }
    else if (table === 'axle'){
      const axlel_tbl_data = [...this.state.axlel_tbl_data];
      axlel_tbl_data[index] = result.data[0];
      this.setState({ axlel_tbl_data });
    } */
  }

  render() {
    const { selectedItems, onSelectedItemsChange } = this.props;
    const deviceReadingColumns = {
      Header: 'Devices',
      columns: [
        {
          accessor: 'checkbox',
          header: "",
          Cell: (props) => (
            <input
              type="checkbox"
              checked={selectedItems.includes(props.original.id)}
              onChange={() => {
                const updatedItems = selectedItems.includes(props.original.id)
                  ? selectedItems.filter(item => item !== props.original.id)
                  : [...selectedItems, props.original.id];
                onSelectedItemsChange(updatedItems);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ),
          width: 50,
        },
        { accessor: 'device_id', Header: 'Device Id', width: 130 },
        { accessor: 'train_no', Header: 'Train No',
          Cell: (props) => props.value,
          width: 60,
        },
        {
          accessor: 'timestamp',
          Header: 'Time',
          Cell: (props) => {
            if (props.value) {
              let tmpDate = new Date(props.value.toString())
              // tmpDate.setHours(tmpDate.getHours() + 5);
              // tmpDate.setMinutes(tmpDate.getMinutes() + 30);
              // return tmpDate.toISOString().substring(0, 16);
              return dateToStr(tmpDate)
            }
            return ''
          },
          width: 150,
        },
        { accessor: 'train_dir', Header: 'Train Dir', width: 70 },
        {
          accessor: 'rake_subtype',
          Header: 'Rake Type',
          width: 160,
          Cell: (props) => {
            return props.value === 'UNKNOWN' && props.row.coach_count > 29 ? 'WAGON' : props.value;
          },
        },
        { accessor: 'coach_count', Header: 'Coach Count', width: 60 },
        { accessor: 'axle_count', Header: 'Axle Count', width: 60 },
        { accessor: 'max_right_temp', Header: 'Max Right Temp' },
        { accessor: 'max_left_temp', Header: 'Max Left Temp' },
        { accessor: 'max_diff_temp', Header: 'Max Diff Temp' },
        {
          accessor: 'avg_speed',
          Header: 'Avg Speed',
          Cell: (props) => {
            const tmpSpeed = Math.floor(parseFloat(props.value) * 3.6).toFixed(2)
            return tmpSpeed > 165 ? ' - ' : tmpSpeed
          },
          width: 60,
        },
        // { accessor: 'hot_axle_type', Header: 'Hot axle type' },
        {
          accessor: 'feedback', Header: 'Remarks', Cell: (props) => {
            return props.value ? props.value.remarks : "";
          },
        },
        {
          accessor: "div",
          Header: "Chart",
          Cell: (props) => (

          <div onClick={(e)=>{e.stopPropagation()}}>
              <CButton color='light'   style={{
        width: "20px", 
        height: "20px", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: 2 
      }}  onClick={(e) => {
                e.stopPropagation(); this.setState({ visible: true, traindata: props.original.id });
              }}>
                <FaChartLine  style={{ cursor: 'pointer'  }} size={10} />
              </CButton>
              </div>
          ),
          width: 50,
        },
        {
          accessor: "div",
          Header: "Axle Data Table",
          Cell: (props) => (

          <div onClick={(e)=>{e.stopPropagation()}}>
              <CButton color='light'   style={{
width: "30px", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        padding: "2px", 
      }}  onClick={(e) => {
                e.stopPropagation(); this.setState({  visibleView:true , traindata: props.original.id });
              }}>
               <span style={{fontSize:"12px"}} >View </span> 
              </CButton>
              </div>
          ),
          width: 70
        }

        // {
        //   accessor: 'train_no', Header: 'TrainNo', Cell: props => {
        //     return (<EditableInput
        //         cellInfo={props}
        //         columnId={props.column.id}
        //         value={ props.value }
        //         table="device"
        //         updateData={(result, index, table) => { this.updateData(result, index, table)}}
        //       />
        //     )
        //   },
        // }
        // { accessor: 'hot_axle', Header: 'Hot Axle', Cell: props => props.value ? "true" : "false"},
        // { accessor: 'is_test', Header: 'TestData', Cell: props => props.value ? "true" : "false"},
      ],
    }

    const coachColumns = [
      {
        Header: 'Coaches',
        columns: [
          // { accessor: 'coach_name', Header: 'Name' },
          { accessor: 'rake_position', Header: 'Rake Position' },
          //  updated Reverce Position code  add the End position  which skip 0
          {
            accessor: 'reverse_position',
            Header: 'Reverse Position',
            Cell: (props) => {
              const { coach_tbl_data } = this.state;
              const rake_positions = coach_tbl_data.map(coach => coach.rake_position);
              const currentPosition = props.original.rake_position;
              if (currentPosition === 0) {
                return 0;
              }
              let sectionStart = 0;
              let sectionEnd = rake_positions.length - 1;
              for (let i = 0; i < rake_positions.length; i++) {
                if (rake_positions[i] === 0 && i < props.index) {
                  sectionStart = i;
                }
                if (rake_positions[i] === 0 && i > props.index) {
                  sectionEnd = i - 1;
                  break;
                }
              }
              const totalCoachesInSection = sectionEnd - sectionStart;
              const reversePosition = totalCoachesInSection - (currentPosition - rake_positions[sectionStart]) + 1;
              return reversePosition;
            },
          }
          ,

          { accessor: 'coach_position', Header: 'Position' },
          {
            accessor: 'timestamp',
            Header: 'Time',
            Cell: (props) => {
              if (props.value) {
                let tmpDate = new Date(props.value.toString())
                // tmpDate.setHours(tmpDate.getHours() + 5);
                // tmpDate.setMinutes(tmpDate.getMinutes() + 30);
                // return tmpDate.toISOString().substring(0, 16);
                return dateToStr(tmpDate)
              }
              return ''
            },
            width: 160,
          },
          { accessor: 'coach_type', Header: 'Type' },
          { accessor: 'coach_subtype', Header: 'Subtype' },
          { accessor: 'max_right_temp', Header: 'Max Right Temp' },
          { accessor: 'max_left_temp', Header: 'Max Left Temp' },
          { accessor: 'max_diff_temp', Header: 'Max Diff Temp' },
          {
            accessor: 'avg_speed',
            Header: 'Avg Speed',
            Cell: (props) => {
              const tmpSpeed = Math.floor(parseFloat(props.value) * 3.6).toFixed(2)
              return tmpSpeed > 250 ? ' - ' : tmpSpeed
            },
          },
          // { accessor: 'hot_axle', Header: 'Hot Axle', Cell: props => props.value ? "true" : "false"},
          // {
          //   accessor: 'remarks', Header: 'Remarks', Cell: (props) => {
          //     return (
          //       <EditableInput
          //         cellInfo={props}
          //         columnId={props.column.id}
          //         value={props.value}
          //         table="coach"
          //         updateData={(result, index, table) => { this.updateData(result, index, table) }}
          //       />
          //     )
          //   },
          // }
        ],
      },
    ]

    const axleColumns = [
      {
        Header: 'Axle',
        columns: [
          { accessor: 'axle_index_coach', Header: 'Axle Index Coach' },
          {
            accessor: 'timestamp',
            Header: 'Time',
            Cell: (props) => {
              if (props.value) {
                let tmpDate = new Date(props.value.toString())
                // tmpDate.setHours(tmpDate.getHours() + 5);
                // tmpDate.setMinutes(tmpDate.getMinutes() + 30);
                // return tmpDate.toISOString().substring(0, 16);
                return dateToStr(tmpDate)
              }
              return ''
            },
            width: 160,
          },
          { accessor: 'right_temp', Header: 'Right Temp.' },
          //{ accessor: 'manual_right_temp', Header: 'Manual Right Temp.' },
          { accessor: 'left_temp', Header: 'Left Temp.' },
          //{ accessor: 'manual_left_temp', Header: 'Manual Left Temp.' },
          { accessor: 'diff_temp', Header: 'Diff Temp.' },
          // { accessor: 'hot_axle', Header: 'Hot Axle', Cell: props => props.value ? "true" : "false"},
          {
            accessor: 'speed',
            Header: 'Speed',
            Cell: (props) => {
              const tmpSpeed = Math.floor(parseFloat(props.value) * 3.6).toFixed(2)
              return tmpSpeed > 250 ? ' - ' : tmpSpeed
            },
          },
          // { accessor: 'action_depot', Header: 'Action Depot' },
          // { accessor: 'action_control', Header: 'Action Control' },
          // { accessor: 'action_insp', Header: 'Action Insp' },
          //{ accessor: 'remarks', Header: 'Remarks', /* Cell: this.renderEditableAxel */ },
          {
            accessor: 'remarks',
            Header: '',
            Cell: (cell) => {
              return (
                <CButton
                  onClick={(e) => {
                    this.props.onEdit(cell.original)
                    // this.setState({
                    //   showFeedbackForm: true,
                    //   selectedAxleData: cell.original,
                    // })
                  }}
                  color="secondary"
                  variant="outline"
                  size="sm"
                >
                  Edit
                </CButton>
                // <EditableInput
                //   cellInfo={props}
                //   columnId={props.column.id}
                //   value={props.value}
                //   table="axle"
                //   updateData={(result, index, table) => { this.updateData(result, index, table) }}
                // />
              )
            },
          },
        ],
      },
    ]

    let tempStatus = null
    let aliveTime = null
    if (typeof this.props.deviceStatus != 'undefined') {
      if (typeof this.props.deviceStatus.mains_status != 'undefined') {
        tempStatus = React.createElement(
          'div',
          { style: { float: 'left', margin: '5px' } },
          `POWER: ${this.props.deviceStatus.mains_status}`,
        )
      }
      if (typeof this.props.deviceStatus.last_alive != 'undefined') {
        aliveTime = React.createElement(
          'div',
          { style: { float: 'left', margin: '5px' } },
          `AT ${dateAddOffset(this.props.deviceStatus.last_alive)}`,
        )
      }
    }
    let deviceName = React.createElement(
      'div',
      { style: { float: 'left', margin: '5px', marginLeft: '40px' } },
      `Station: ${this.props.deviceID}`,
    )

    return (
      <>
        <ReactTable
        onClick={(e)=>{ e.stopPropagation()}}
          data={this.props.data}
          columns={[
            {
              ...deviceReadingColumns,
              Header: (
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div style={{ flex: '1', overflow: 'hidden' }} >
                    {tempStatus}
                    {aliveTime}
                    {deviceName}

                  </div>
                </div>
              )
            },
          ]}

          defaultPageSize={this.props.deviceRowCount}
          showPagination={true}
          defaultSorted={[
            {
              id: 'timestamp',
              desc: true,
            },
          ]}
          // ref={this.tableElement}
          style={{ marginBottom: '12px' }}
          expanded={this.state.expanded}
          className="-striped -highlight"
          getTheadThProps={() => {
            return {
              style: {
                background: '#3a61c2',
                color: 'white',
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance, expanded) => {
            return {
              onClick: (e) => {
                const expanded = {}
                expanded[rowInfo.viewIndex] = this.state.expanded[rowInfo.viewIndex] ? false : true
                if (!expanded[rowInfo.viewIndex]) {
                  this.setState({
                    expanded: {},
                    expandedCoach: {},
                    coach_tbl_data: [],
                  })
                } else {
                  this.setState({ expanded, coach_tbl_data: [] })
                  this.getCoachData(this.props.data[rowInfo.index].id)
                }
              },
              style: {
                background:
                  // rowInfo && this.props.data[rowInfo.index].hot_axle && 'rgba(229, 83, 83, 0.7)',
                  (rowInfo && this.props.data[rowInfo.index].hot_axle_type && this.props.data[rowInfo.index].hot_axle_type === 'red') ? JSON.parse(localStorage.getItem('selectedColors')).red :
                    (rowInfo && this.props.data[rowInfo.index].hot_axle_type && this.props.data[rowInfo.index].hot_axle_type === 'yellow') ? JSON.parse(localStorage.getItem('selectedColors')).yellow :
                      (rowInfo && this.props.data[rowInfo.index].hot_axle_type && this.props.data[rowInfo.index].hot_axle_type === 'green') ? JSON.parse(localStorage.getItem('selectedColors')).green :
                        (rowInfo && this.props.data[rowInfo.index].hot_axle_type && this.props.data[rowInfo.index].hot_axle_type === 'gray') && JSON.parse(localStorage.getItem('selectedColors')).gray,
              },
            }
          }}
          SubComponent={(row) => (
            <div style={{ padding: '20px' }}>
              {this.state.coach_tbl_data.length > 0 && (
                <ReactTable
                  data={this.state.coach_tbl_data}
                  columns={coachColumns}
                  defaultSorted={[
                    {
                      id: 'coach_position',
                      desc: false,
                    },
                  ]}
                  expanded={this.state.expandedCoach}
                  getTheadThProps={() => {
                    return {
                      style: {
                        background: '#3a61c2',
                        color: 'white',
                      }
                    };
                  }}
                  // PageSize={this.state.coach_tbl_data.length}
                  defaultPageSize={this.state.coach_tbl_data.length}
                  showPagination={false}
                  getTrProps={(state, rowInfo, column, instance, expanded) => {
                    return {
                      onClick: (e) => {
                        const expandedCoach = {}
                        expandedCoach[rowInfo.viewIndex] = this.state.expandedCoach[
                          rowInfo.viewIndex
                        ]
                          ? false
                          : true
                        if (!expandedCoach[rowInfo.viewIndex]) {
                          this.setState({
                            expandedCoach: {},
                          })
                        }
                        this.setState({ expandedCoach, axlel_tbl_data: [] })
                        this.getAxleData(this.state.coach_tbl_data[rowInfo.index].id)
                        // console.log("-----", this.state.coach_tbl_data[rowInfo.viewIndex]);
                      },
                      style: {
                        background:
                          (rowInfo && this.state.coach_tbl_data[rowInfo.index].hot_axle_type && this.state.coach_tbl_data[rowInfo.index].hot_axle_type === 'red') ? JSON.parse(localStorage.getItem('selectedColors')).red :
                            (rowInfo && this.state.coach_tbl_data[rowInfo.index].hot_axle_type && this.state.coach_tbl_data[rowInfo.index].hot_axle_type === 'yellow') ? JSON.parse(localStorage.getItem('selectedColors')).yellow :
                              (rowInfo && this.state.coach_tbl_data[rowInfo.index].hot_axle_type && this.state.coach_tbl_data[rowInfo.index].hot_axle_type === 'green') ? JSON.parse(localStorage.getItem('selectedColors')).green :
                                (rowInfo && this.state.coach_tbl_data[rowInfo.index].hot_axle_type && this.state.coach_tbl_data[rowInfo.index].hot_axle_type === 'gray') && JSON.parse(localStorage.getItem('selectedColors')).gray,

                      },
                    }
                  }}
                  SubComponent={(row) => {
                    return (
                      <div style={{ padding: '20px' }}>
                        {this.state.axlel_tbl_data.length > 0 && (
                          <ReactTable
                            data={this.state.axlel_tbl_data}
                            columns={axleColumns}
                            defaultSorted={[
                              {
                                id: 'axle_index_coach',
                                desc: false,
                              },
                            ]}
                            getTheadThProps={() => {
                              return {
                                style: {
                                  background: '#3a61c2',
                                  color: 'white',
                                }
                              };
                            }}
                            defaultPageSize={this.state.axlel_tbl_data.length}
                            showPagination={false}
                            getTrProps={(state, rowInfo, column, instance, expanded) => {
                              return {
                                style: {
                                  background:
                                    // rowInfo &&
                                    // this.state.axlel_tbl_data[rowInfo.index].hot_axle &&
                                    // 'rgba(229, 83, 83, 0.7)',
                                    (rowInfo && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type === 'red') ? JSON.parse(localStorage.getItem('selectedColors')).red :
                                      (rowInfo && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type === 'yellow') ? JSON.parse(localStorage.getItem('selectedColors')).yellow :
                                        (rowInfo && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type === 'green') ? JSON.parse(localStorage.getItem('selectedColors')).green :
                                          (rowInfo && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type && this.state.axlel_tbl_data[rowInfo.index].hot_axle_type === 'gray') && JSON.parse(localStorage.getItem('selectedColors')).gray,
                                },
                              }
                            }}
                          />
                        )}
                      </div>
                    )
                  }}
                />
              )}
            </div>
          )}
        />
        {this.state.visible && (
          <CModal
          
            size='xl'
            color='light'
            aria-labelledby="VerticallyCenteredExample"
            alignment="center"
            visible={this.state.visible}
            className="modal-dialog-centered modal-backdrop-transparent"
          >
            <div  style={{ cursor: 'pointer', right:0, display:"flex", justifyContent:"flex-end" ,  }}>
            <IoClose
              size={30}
            className='text-right'
     
              onClick={(e) => {
                this.setState({ visible: false });
                e.stopPropagation();
              }}
            />
             </div>
            <CModalBody >
              <DataChart
                device={this.state.traindata}
                onClick={(e) => {
                  this.setState({ visible: false });
                  e.stopPropagation();
                }}
              />
            </CModalBody>
          </CModal>
        )},
         {this.state.visibleView && (
          <CModal
          size="xl"
          color="light"
          aria-labelledby="VerticallyCenteredExample"
          alignment="center"
          visible={this.state.visibleView}
          className="modal-dialog-centered modal-backdrop-transparent"
          onClose={() => this.setState({ visibleView: false })} 
        >
          
          <div  style={{ cursor: 'pointer', right:0, display:"flex", justifyContent:"flex-end" ,  }}>
            <IoClose
              size={30}
            className='text-right'
     
              onClick={(e) => {
                this.setState({ visibleView: false });
                e.stopPropagation();
              }}
            />
             </div>
         
          
          <CModalBody>
          <ViewTable
           device={this.state.traindata}
           onClick={(e) => {
             this.setState({ visibleView: false });
             e.stopPropagation();
           }}
          />
          </CModalBody>
        </CModal>
        
        )}
      </>
    )
  }
}

DRTable.propTypes = {
  data: PropTypes.any,
  deviceID: PropTypes.any,
  deviceStatus: PropTypes.any,
  deviceRowCount: PropTypes.number,
  updateDeviceData: PropTypes.func,
  onEdit: PropTypes.func,
  selectedItems: PropTypes.array,
  onSelectedItemsChange: PropTypes.func
}

export default DRTable
