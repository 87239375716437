import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactTable from 'react-table-6';
import { AppSidebar, AppFooter, AppHeader } from '../../../components/index';
import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
  CCard,
  CCardHeader,
} from '@coreui/react';
import AppBarChart from 'src/components/AppBarChart';
import AppPieChart from 'src/components/AppPieChart';


// eslint-disable-next-line react/prop-types
const ReadingCount = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line react/prop-types
  const [countData, setCountData] = useState([]);
  const [division, setDivision] = useState('device_id');
  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [table2data, setTable2data] = useState([]);
  const [grpBy, setgrpBy] = useState('device_id');
  const [startDate3, setStartDate3] = useState(null);
  const [endDate3, setEndDate3] = useState(null);
  const [table3data, setTable3data] = useState([]);
  const [piedata, setPiedata] = useState([]);
  const [isTable2Loading, setIsTable2Loading] = useState(true);
 const [isTable4Loading, setIsTable4Loading] = useState(true);
  const [tableData, setTableData] = useState();
  const getData = async () => {
    const response = await axios.get('https://api.irhabd.com/v1/readings-device');
    const readingsDevice = response.data;
    const deviceList = readingsDevice.map((d) => d.device_id);
    setDeviceList(deviceList);
  };

  const table_2_column = [
    {
      Header: `${division.toUpperCase()}`,
      accessor: 'grp',
      id: 'grp',
    },
    {
      Header: 'Level 1',
      accessor: 'countR',
      id: 'countR',
    },
    {
      Header: 'Level 1 (with Feedback)',
      accessor: 'countRF',
      id: 'countRF',
    },
    {
      Header: 'Level 1 (without Feedback)',
      accessor: (row) => row.countR - row.countRF,
      id: 'level1WithoutFeedback',
    },
    {
      Header: 'Level 2',
      accessor: 'countY',
      id: 'countY',
    },
    {
      Header: 'Level 2 (with Feedback)',
      accessor: 'countYF',
      id: 'countYF',
    },
    {
      Header: 'Level 2 (without Feedback)',
      accessor: (row) => row.countY - row.countYF,
      id: 'level2WithoutFeedback',
    },
    {
      Header: 'Level 3',
      accessor: 'countG',
      id: 'countG',
    },
    {
      Header: 'Level 3 (with Feedback)',
      accessor: 'countGF',
      id: 'countGF',
    },
    {
      Header: 'Level 3 (without Feedback)',
      accessor: (row) => row.countG - row.countGF,
      id: 'level3WithoutFeedback',
    },
    {
      Header: 'Fault Type',
      accessor: (row) => {
        if (row.faultTypes.length === 0) {
          return '-';
        } else {
          return row.faultTypes.map((val) => `${val.axle_fault_type} (${val.count})`).join(', ');
        }
      },
      id: 'faultType',
      width: 450,
    },
  ];
  const table_4_column = [
    {
      accessor: 'grp',
      Header: `${division.toUpperCase()}`,
    },

    {
      accessor: 'countCR',
      Header: 'Level 1',
    },
    {
      accessor: 'countCY',
      Header: 'Level 2',
    },
    {
      accessor: 'countCG',
      Header: 'Level 3',
    },
    { Header: null, accessor: null, width: 50 },
  ];

  const fetchDataRake = () => {
    let url = `https://api.irhabd.com/v1/stats/rakes?groupBy=hot_axle_type&groupBy=rake_type&groupBy=${grpBy}`;
    if (startDate3) {
      url += `&startDate=${startDate3}`;
    }
    if (endDate3) {
      url += `&endDate=${endDate3}`;
    }
    axios
      .get(url)
      .then((response) => {
        let temp = [];
        let divisionMap = new Map();
        setTableData(response.data);
        Object.values(response.data).forEach((rslt) => {
          let divisionKey = rslt[grpBy] || rslt['division_id'];
          let hotAxleType = rslt.hot_axle_type;
          let rakeSubtype = rslt.rake_type;

          if (!divisionMap.has(divisionKey)) {
            divisionMap.set(divisionKey, { grp: divisionKey, values: [] });
          }

          let division = divisionMap.get(divisionKey);
          division.values.push({
            type: rakeSubtype,
            hot_axle_type: hotAxleType,
            count: rslt.count,
          });
        });

        divisionMap.forEach((value) => {
          if (Array.isArray(value.values)) {
            const values = value.values.filter((val) => val.type !== 'UNKNOWN');
            temp.push({ ...value, values });
          } else {
            temp.push({ ...value });
          }
        });
        setTable3data(temp);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchDataRake();
  }, [startDate3, endDate3, grpBy]);

  // const fetchDataCoach = () => {
  //   setIsTable4Loading(true);
  //   let url = `https://api.irhabd.com/v1/stats/coaches?groupBy=hot_axle_type&groupBy=${division}`;
  //   if (startDate2) {
  //     url += `&startDate=${startDate2}`;
  //   }
  //   if (endDate2) {
  //     url += `&endDate=${endDate2}`;
  //   }
  //   axios.get(url).then((response) => {
  //     let temp = [];
  //     let divisionMap = new Map();
  //     Object.values(response.data).map((rslt) => {
  //       if (division === 'division') {
  //         if (!divisionMap.has(rslt['division_id'])) {
  //           divisionMap.set(rslt['division_id'], {
  //             grp: rslt['division_id'],
  //             countCR: 0,
  //             countCY: 0,
  //             countCG: 0,
  //           });
  //         }
  //         if (rslt.hot_axle_type === 'red') {
  //           divisionMap.get(rslt['division_id'])['countCR'] = rslt.count;
  //         }
  //         if (rslt.hot_axle_type === 'yellow') {
  //           divisionMap.get(rslt['division_id'])['countCY'] = rslt.count;
  //         }
  //         if (rslt.hot_axle_type === 'green') {
  //           divisionMap.get(rslt['division_id'])['countCG'] = rslt.count;
  //         }
  //       } else {
  //         if (!divisionMap.has(rslt[division])) {
  //           divisionMap.set(rslt[division], {
  //             grp: rslt[division],
  //             countCR: 0,
  //             countCY: 0,
  //             countCG: 0,
  //           });
  //         }
  //         if (rslt.hot_axle_type === 'red') {
  //           divisionMap.get(rslt[division])['countCR'] = rslt.count;
  //         }
  //         if (rslt.hot_axle_type === 'yellow') {
  //           divisionMap.get(rslt[division])['countCY'] = rslt.count;
  //         }
  //         if (rslt.hot_axle_type === 'green') {
  //           divisionMap.get(rslt[division])['countCG'] = rslt.count;
  //         }
  //       }
  //     });
  //     divisionMap.forEach((value) => {
  //       temp.push(value);
  //     });
  //     setTable4data(temp);
  //     setIsTable4Loading(false);
  //   });
  // };

  const fetchData = () => {
    setIsTable2Loading(true);

    let url1 = `https://api.irhabd.com/v1/stats/rakes?groupBy=hot_axle_type&groupBy=${division}`;
    let url2 = `https://api.irhabd.com/v1/stats/rakes?feedbackEnterered=true&groupBy=hot_axle_type&groupBy=${division}`;
    let url3 = `https://api.irhabd.com/v1/stats/rakes?groupBy=fault_type&groupBy=${division}`;

    if (startDate2) {
      url1 += `&startDate=${startDate2}`;
      url2 += `&startDate=${startDate2}`;
      url3 += `&startDate=${startDate2}`;
    }
    if (endDate2) {
      url1 += `&endDate=${endDate2}`;
      url2 += `&endDate=${endDate2}`;
      url3 += `&endDate=${endDate2}`;
    }
    Promise.all([axios.get(url1), axios.get(url2), axios.get(url3)])
      .then(([response1, response2, response3]) => {
        let temp = [];
        let divisionMap = new Map();
        Object.values(response1.data).forEach((rslt) => {
          let key = division === 'division' ? rslt['division_id'] : rslt[division];
          if (!divisionMap.has(key)) {
            divisionMap.set(key, {
              grp: key,
              countR: 0,
              countY: 0,
              countG: 0,
              countRF: 0,
              countYF: 0,
              countGF: 0,
              faultTypes: new Map(),
            });
          }
          if (rslt.hot_axle_type === 'red') {
            divisionMap.get(key)['countR'] = rslt.count;
          }
          if (rslt.hot_axle_type === 'yellow') {
            divisionMap.get(key)['countY'] = rslt.count;
          }
          if (rslt.hot_axle_type === 'green') {
            divisionMap.get(key)['countG'] = rslt.count;
          }
        });

        Object.values(response2.data).forEach((rslt) => {
          let key = division === 'division' ? rslt['division_id'] : rslt[division];
          if (!divisionMap.has(key)) {
            divisionMap.set(key, {
              grp: key,
              countR: 0,
              countY: 0,
              countG: 0,
              countRF: 0,
              countYF: 0,
              countGF: 0,
              faultTypes: new Map(),
            });
          }
          if (rslt.train_hot_level === 'red') {
            divisionMap.get(key)['countRF'] = rslt.count;
          }
          if (rslt.train_hot_level === 'yellow') {
            divisionMap.get(key)['countYF'] = rslt.count;
          }
          if (rslt.train_hot_level === 'green') {
            divisionMap.get(key)['countGF'] = rslt.count;
          }
        });

        Object.values(response3.data).forEach((rslt) => {
          let key = division === 'division' ? rslt['division_id'] : rslt[division];
          let axleFaultType = rslt.axle_fault_type || 'N.A.';
          if (!divisionMap.has(key)) {
            divisionMap.set(key, {
              grp: key,
              countR: 0,
              countY: 0,
              countG: 0,
              countRF: 0,
              countYF: 0,
              countGF: 0,
              faultTypes: new Map(),
            });
          }
          if (!divisionMap.get(key).faultTypes.has(axleFaultType)) {
            divisionMap.get(key).faultTypes.set(axleFaultType, {
              axle_fault_type: axleFaultType,
              count: 0,
            });
          }
          divisionMap.get(key).faultTypes.get(axleFaultType).count += parseInt(rslt.count, 10);
        });

        divisionMap.forEach((value) => {
          value.faultTypes = Array.from(value.faultTypes.values());
          temp.push(value);
        });

        setTable2data(temp);
        setIsTable2Loading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsTable2Loading(false);
      });
  };
  useEffect(() => {
    // fetchDataCoach();
    fetchData();
  }, [startDate2, endDate2, division]);

  const getReadings = async (queryParams = {}) => {
    const params = {
      countsOnly: '',
      countGroupBy: '',
      discardCalibration: true,
      // eslint-disable-next-line react/prop-types
      device: deviceList.join(','),
      ...queryParams,
    };
    const response = await axios.get('https://api.irhabd.com/v1/readings', {
      params,
    });
    let responseArray = response.data;
    return responseArray;
  };

  const getAllReadings = async (options = { withDateFilter: false }) => {
    setIsLoading(true);
    let x = deviceList.reduce((obj, item) => ((obj[item] = {}), obj), {});
    let dateQueryParam = {};
    setIsFiltered(options.withDateFilter);
    if (options.withDateFilter) {
      dateQueryParam = {
        startDate: startDate ? new Date(startDate).toISOString() : null,
        endDate: endDate ? new Date(endDate).toISOString() : null,
      };
    }
    let a = await getReadings(dateQueryParam);
    a.forEach((item) => {
      if (x[item.device_id]) {
        x[item.device_id]['count'] = item.count;
        x[item.device_id]['axles'] = item.axles;
        x[item.device_id]['coaches'] = item.coaches;
      }
    });
    // let x = a.reduce((obj, item) => ((obj[item.device_id] = { count: item.count }), obj), {})
    const b = await getReadings({
      hotAxle: true,
      ...dateQueryParam,
    });
    b.forEach((item) => {
      // eslint-disable-next-line no-debugger
      if (x[item.device_id]) {
        x[item.device_id]['hot_axle'] = item.count;
      }
    });
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const c = await getReadings({
      startDate: date,
    });
    c.forEach((item) => {
      // eslint-disable-next-line no-debugger
      if (x[item.device_id]) {
        x[item.device_id]['today'] = item.count;
        x[item.device_id]['today_axles'] = item.axles;
        x[item.device_id]['today_coaches'] = item.coaches;
      }
    });
    const d = await getReadings({
      startDate: date,
      hotAxle: true,
    });
    d.forEach((item) => {
      if (x[item.device_id]) {
        x[item.device_id]['today_hot_axle'] = item.count;
      }
    });

    // console.log(Object.entries(x));
    a = Object.entries(x).map((item) => ({
      device_id: item[0],
      ...item[1],
    }));
    // console.log(o)
    // console.log(a)
    setCountData(a);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (deviceList && deviceList.length > 0) {
      // eslint-disable-next-line react/prop-types
      setCountData(deviceList.map((device) => ({ device_id: device })));
      getAllReadings();
    }
  }, [deviceList]);

  const columns = [
    { accessor: 'device_id', Header: 'Device Id' },
    {
      accessor: 'count',
      Header: `Total rakes${isFiltered ? '(filtered)' : ''}`,
      Cell: ({ value }) => {
        return value ?? 0;
      },
    },
    {
      accessor: 'hot_axle',
      Header: `HABD alerts${isFiltered ? '(filtered)' : ''}`,
      Cell: ({ value }) => {
        return value ?? 0;
      },
    },
    {
      accessor: 'coaches',
      Header: `Total Rolling Stock${isFiltered ? '(filtered)' : ''}`,
      Cell: ({ value }) => {
        return value ?? 0;
      },
    },
    {
      accessor: 'axles',
      Header: `Total axles${isFiltered ? '(filtered)' : ''}`,
      Cell: ({ value }) => {
        let a = value ?? 0;
        return a % 2 === 0 ? a : a - 1;
      },
    },
    {
      accessor: 'today',
      Header: 'Rakes today',
      Cell: ({ value }) => {
        return value ?? 0;
      },
    },
    {
      accessor: 'today_coaches',
      Header: 'Rolling Stock today',
      Cell: ({ value }) => {
        return value ?? 0;
      },
    },
    {
      accessor: 'today_axles',
      Header: 'Axles today',
      Cell: ({ value }) => {
        let a = value ?? 0;
        return a % 2 === 0 ? a : a - 1;
      },
    },
    {
      accessor: 'today_hot_axle',
      Header: 'HABD alerts today',
      Cell: ({ value }) => {
        return value ?? 0;
      },
    },
  ];

  const level1Data = {};
  tableData?.forEach((item) => {
    const dynamicKey = item.device_id || item.station || item.division || item.division_id;
    const count = item.count;
    const rake_type = item.rake_type;
    const hot_axle_type = item.hot_axle_type;
    if (hot_axle_type === 'red' && (rake_type === 'COACHING' || rake_type === 'WAGON')) {
      if (!level1Data[dynamicKey]) {
        level1Data[dynamicKey] = { dynamicKey, coaching: 0, wagon: 0 };
      }
      if (rake_type === 'COACHING') {
        level1Data[dynamicKey].coaching += parseInt(count, 10);
      } else if (rake_type === 'WAGON') {
        level1Data[dynamicKey].wagon += parseInt(count, 10);
      }
    }
  });

  const level2Data = {};
  tableData?.forEach((item) => {
    const dynamicKey = item.device_id || item.station || item.division || item.division_id;
    const count = item.count;
    const rake_type = item.rake_type;
    const hot_axle_type = item.hot_axle_type;
    if (hot_axle_type === 'yellow' && (rake_type === 'COACHING' || rake_type === 'WAGON')) {
      if (!level2Data[dynamicKey]) {
        level2Data[dynamicKey] = { dynamicKey, coaching: 0, wagon: 0 };
      }
      if (rake_type === 'COACHING') {
        level2Data[dynamicKey].coaching += parseInt(count, 10);
      } else if (rake_type === 'WAGON') {
        level2Data[dynamicKey].wagon += parseInt(count, 10);
      }
    }
  });
  // Convert aggregated data to arrays
  const level1ChartData = Object.values(level1Data);
  const level2ChartData = Object.values(level2Data);
  const handleDropDown = (e) => {
    console.log(e.target.value, "value");
    const data = table2data.filter((val) => val.grp === e.target.value);
    setPiedata(data)
    console.log(data, "data");
}
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-white">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CCard>
            <CCardHeader>Statistics Report</CCardHeader>
            <CRow className="g-3 row py-4 align-items-center">
              <CCol>
                <CFormInput
                  type="datetime-local"
                  placeholder="Start date"
                  floatingLabel={'Start date'}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ marginLeft: '5px' }}
                />
              </CCol>
              <CCol>
                <CFormInput
                  disabled={!startDate}
                  min={startDate}
                  type="datetime-local"
                  placeholder="End date"
                  floatingLabel={'End date'}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </CCol>
              <CCol>
                <CButton
                  onClick={() => {
                    getAllReadings({ withDateFilter: true });
                  }}
                  color="primary"
                  variant="outline"
                  size="lg"
                  disabled={!startDate && !endDate}
                >
                  Submit
                </CButton>
              </CCol>
              {isFiltered ? (
                <CCol>
                  <CButton
                    color="warning"
                    variant="outline"
                    onClick={() => {
                      setStartDate('');
                      setEndDate('');
                      getAllReadings({ withDateFilter: false });
                    }}
                    size="lg"
                  >
                    Clear Filter
                  </CButton>
                </CCol>
              ) : null}
            </CRow>
            <ReactTable loading={isLoading} data={countData} columns={columns} />
          </CCard>
          <CCard style={{ marginTop: '20px' }}>
            <CCardHeader>Alert Table</CCardHeader>
            <CRow className="g-2 row py-4 align-items-left">
              <CCol xs="auto">
                <CFormInput
                  type="date"
                  placeholder="Start date"
                  floatingLabel="Start date"
                  value={startDate2}
                  onChange={(e) => setStartDate2(e.target.value)}
                  style={{ marginLeft: '5px' }}
                />
              </CCol>
              <CCol xs="auto">
                <CFormInput
                  type="date"
                  placeholder="End date"
                  floatingLabel="End date"
                  value={endDate2}
                  onChange={(e) => setEndDate2(e.target.value)}
                  style={{ marginLeft: '5px' }}
                />
              </CCol>
              <CCol xs="auto">
                <CFormLabel style={{ marginLeft: '5px' }}>Group By:</CFormLabel>
              </CCol>
              <CCol xs="auto">
                <CFormSelect
                  size="sm"
                  name="division"
                  onChange={(e) => setDivision(e.target.value)}
                >
                  <option value="device_id">Device ID</option>
                  <option value="station">Station</option>
                  <option value="division">Division</option>
                </CFormSelect>
              </CCol>
            </CRow>
            <CCard>
              <CCardHeader>By Rakes</CCardHeader>
              <ReactTable
                loading={isTable2Loading}
                data={table2data}
                columns={table_2_column}
                defaultPageSize={5}
              />
            </CCard>
            {/* <CCard style={{ marginTop: '20px' }}>
              <CCardHeader>By Coaches</CCardHeader>
              <ReactTable
                loading={isTable4Loading}
                data={table4data}
                columns={table_4_column}
                defaultPageSize={5}
              />
            </CCard> */}
            <div style={{ marginTop: '20px' }}>
              <CRow>
                <CCol>
                  <CCard className="text-center rounded-0" style={{ alignItems: "center" }}>
                    <CCardHeader > By Rakes</CCardHeader>

                    <AppBarChart
                      name1="Level 1"
                      name2="Level 2"
                      dataKey1="countR"
                      dataKey2="countY"
                      xAixData="grp"
                      data={table2data}
                      division={division}
                      Loding={isTable2Loading}
                      style={{ textAlign: "center" }}
                    />
                  </CCard>
                </CCol>
                <CCol>

                <CCard className="text-center rounded-0">
      <CCardHeader>By Rakes Pie chart</CCardHeader>
      <CCol className="d-flex flex-column align-items-center">
      {piedata.length > 0 ? piedata.map((val) => (<>      <CRow>    
        <CCol  className='d-flex flex-row align-items-center'>  {(val.countRF > 0 && (val.countR - val.countRF) > 0) ? <>
        <AppPieChart
          data ={piedata.map((val) => [
          val.countRF,
          (val.countR - val.countRF),
        ]).flat()}
          lables={["Level 1 With Feed Back","Level 1 without feed Back"]}
          backgroundColor={['#FF8042', '#FFBB28', '#00C49F', '#FF0000', '#FF0000', '#FF0000']}
        /> </>:<> <div >DATA UNAVAILABLE</div></>}
      {(val.countYF>0,(val.countY - val.countYF) > 0)? <>  <AppPieChart 
          data ={piedata.map((val) => [
          val.countYF,
          ( val.countY - val.countYF),
        ]).flat()}
          lables={["Level 2 With Feed Back","Level 2 without feed Back",]}
          backgroundColor={[  '#FFBB28', '#00C49F' , '#FF0000', ]}
        /> </> : <> <div  >DATA UNAVAILABLE</div></> }
        </CCol>
      <CCol className="d-flex  align-items-center justify-content-around">{(val.faultTypes.length > 0  ) ? <>
                                <AppPieChart
                                  data={val.faultTypes.map((type) => [type.count])}
                                  lables={val.faultTypes.map((type) => [type.axle_fault_type])}
                                  backgroundColor={['#36A2EB', '#FFCE56', '#FF6384','#4BC0C0', '#FF9F40', '#9966FF', '#FF6384', '#36A2EB']}
                                /> </> : <><div >DATA UNAVAILABLE</div></>  }
                                </CCol>
        </CRow> </>) ) : <><div>Select the Device </div></>}
        <div className=" mt-3" style={{width:"200px"}}>
          <CFormSelect
            size="sm"
            name="exportOption"
            onChange={(e)=>{handleDropDown(e)}}
          > <option >Select the Device</option>
            {
              table2data.map((val, index) => (
                <>
                <option key={index} value={val.grp}>{val.grp}</option>
                </>
              ))
            }
          </CFormSelect>
        </div>
      </CCol>
    </CCard>
                </CCol>
              </CRow>
            </div>
          </CCard>

          <CCard style={{ marginTop: '20px' }}>
            <CCardHeader>Alert Table by Rake Type</CCardHeader>
            <CRow className="g-2 row py-4 align-items-left">
              <CCol xs="auto">
                <CFormInput
                  type="date"
                  placeholder="Start date"
                  floatingLabel={'Start date'}
                  value={startDate3}
                  onChange={(e) => setStartDate3(e.target.value)}
                  style={{ marginLeft: '5px' }}
                />
              </CCol>
              <CCol xs="auto">
                <CFormInput
                  type="date"
                  placeholder="End date"
                  floatingLabel={'End date'}
                  value={endDate3}
                  onChange={(e) => setEndDate3(e.target.value)}
                  style={{ marginLeft: '5px' }}
                />
              </CCol>
              <CCol xs="auto">
                <CFormLabel style={{ marginLeft: '5px' }}>Group By:</CFormLabel>
              </CCol>
              <CCol xs="auto">
                <CFormSelect size="sm" name="grpBy" onChange={(e) => setgrpBy(e.target.value)}>
                  <option value="device_id">Device ID</option>
                  <option value="station">Station</option>
                  <option value="division">Division</option>
                </CFormSelect>
              </CCol>
            </CRow>

            <ReactTable
              loading={isLoading}
              data={table3data}
              columns={[
                {
                  Header: `${grpBy.toUpperCase()}`,
                  accessor: 'grp',
                  style: { whiteSpace: 'nowrap' },
                  width: 180,
                },
                {
                  Header: 'Level 1',
                  accessor: 'values',
                  Cell: (value) => {
                    const filteredValues = value.original.values
                      .filter(
                        (rt) =>
                          rt.hot_axle_type !== 'gray' &&
                          rt.hot_axle_type !== null &&
                          rt.hot_axle_type === 'red' &&
                          (rt.type === 'COACHING' || rt.type === 'WAGON' || rt.type === 'UNKNOWN'),
                      )
                      .sort((a, b) => {
                        const typeOrder = { COACHING: 1, WAGON: 2, UNKNOWN: 3 };
                        return typeOrder[a.type] - typeOrder[b.type];
                      });

                    return (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {filteredValues.length > 0 ? (
                          filteredValues.map((rt, index) => (
                            <div key={index} style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                              {rt.type}({rt.count})
                            </div>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  Header: 'Level 2',
                  accessor: 'values',
                  Cell: (value) => {
                    const filteredValues = value.original.values
                      .filter(
                        (rt) =>
                          rt.hot_axle_type !== 'gray' &&
                          rt.hot_axle_type !== null &&
                          rt.hot_axle_type === 'yellow' &&
                          (rt.type === 'COACHING' || rt.type === 'WAGON' || rt.type === 'UNKNOWN'),
                      )
                      .sort((a, b) => {
                        const typeOrder = { COACHING: 1, WAGON: 2, UNKNOWN: 3 };
                        return typeOrder[a.type] - typeOrder[b.type];
                      });

                    return (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {filteredValues.length > 0 ? (
                          filteredValues.map((rt, index) => (
                            <div key={index} style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                              {rt.type}({rt.count})
                            </div>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  Header: 'Level 3',
                  accessor: 'values',
                  Cell: (value) => {
                    const filteredValues = value.original.values
                      .filter(
                        (rt) =>
                          rt.hot_axle_type !== 'gray' &&
                          rt.hot_axle_type !== null &&
                          rt.hot_axle_type === 'green' &&
                          (rt.type === 'COACHING' || rt.type === 'WAGON' || rt.type === 'UNKNOWN'),
                      )
                      .sort((a, b) => {
                        const typeOrder = { COACHING: 1, WAGON: 2, UNKNOWN: 3 };
                        return typeOrder[a.type] - typeOrder[b.type];
                      });

                    return (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {filteredValues.length > 0 ? (
                          filteredValues.map((rt, index) => (
                            <div key={index} style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                              {rt.type}({rt.count})
                            </div>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              defaultPageSize={5}
            />

            <CCard
              className="text-center rounded-0 text-left align-items-center"
              style={{ marginTop: '20px' }}
            >
              <CCardHeader className="w-100 "> Chart of Alert Table by Rake Type </CCardHeader>

              <div style={{ marginTop: '20px' }}>
                <CRow>
                  <CCol>
                    <CCard className="text-center w-100 rounded-0">
                      <CCardHeader>Level 1</CCardHeader>
                      <AppBarChart
                        data={level1ChartData}
                        dataKey1="coaching"
                        dataKey2="wagon"
                        xAixData="dynamicKey"
                        name1="Level 1 Coaching"
                        name2="Level 1 Wagon"
                        division={grpBy}
                        Loding={false}
                      />
                    </CCard>
                  </CCol>
                  <CCol>
                    <CCard className="text-center w-100 rounded-0">
                      <CCardHeader> Level 2</CCardHeader>
                      <AppBarChart
                        data={level2ChartData}
                        dataKey1="coaching"
                        dataKey2="wagon"
                        xAixData="dynamicKey"
                        name1="Level 2 Coaching"
                        name2="Level 2 Wagon"
                        Loding={false}
                        division={grpBy}
                      />
                    </CCard>
                  </CCol>
                </CRow>
              </div>
            </CCard>
          </CCard>
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default ReadingCount;
